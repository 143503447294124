import { ReactNode } from 'react';
import { TFunction, Trans } from 'react-i18next';

import { DeviceType } from '../types';
import {
  AiAlert,
  AreaAlert,
  AssetAlert,
  DasairAlert,
  DascartAlert,
  DascasAlert,
  DasConcreteAlert,
  DasGasAlert,
  DaslockAlert,
  DasloopAlert,
  DaspowerAlert,
  DastempAlert,
  DastrackAlert,
  DaswaterLAlert,
  DeviationAlert,
} from '../types/Alert';
import { Pipe } from '../types/Resource';

import { AlertType } from './getAlertIcon';
import { findTheClosestPipe } from './getWaterLevel';

const toDasLoopAlertDescription = (alert: DasloopAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;

  switch (alert.alert.type) {
    case AlertType.BODY_TEMPERATURE_TOO_HIGH:
    case AlertType.BODY_TEMPERATURE_TOO_LOW:
      description = `${t(`alert-type.${alert.alert.type}`)} ( ${
        alert.alert.value.actual
      } ${alert.alert.value.threshold} ℃ )`;
      descriptionNode = (
        <>
          {t(`alert-type.${alert.alert.type}`)} ({' '}
          <span className="alert-value">{alert.alert.value.actual} ℃</span> /{' '}
          {alert.alert.value.threshold} ℃ )
        </>
      );
      break;
    case AlertType.HEART_RATE_TOO_HIGH:
    case AlertType.HEART_RATE_TOO_LOW:
      description = `${t(`alert-type.${alert.alert.type}`)} ( ${
        alert.alert.value.actual
      } ${t('heart-rate-unit')} / ${alert.alert.value.threshold} ${t(
        'heart-rate-unit',
      )})`;
      descriptionNode = (
        <>
          {t(`alert-type.${alert.alert.type}`)} {'( '}
          <span className="alert-value">
            {alert.alert.value.actual} {t('heart-rate-unit')}
          </span>{' '}
          / {alert.alert.value.threshold} {t('heart-rate-unit')} {' )'}
        </>
      );
      break;
    case AlertType.FREE_FALL:
    case AlertType.PANIC_BUTTON:
    case AlertType.STANDSTILL:
      description = t(`alert-type.${alert.alert.type}`);
      descriptionNode = t(`alert-type.${alert.alert.type}`);
      break;
    case AlertType.REGULAR_SIGNAL_MISSING:
      description = `${t(`alert-type.${alert.alert.type}`, {
        count: alert.alert.value.timeout,
      })}`;
      descriptionNode = `${t(`alert-type.${alert.alert.type}`, {
        count: alert.alert.value.timeout,
      })}`;
      break;
    case AlertType.CAP_OFF:
      description = `${alert.alert.value?.map((v) => v.name).join(', ') ?? ''}`;
      descriptionNode = `${
        alert.alert.value?.map((v) => v.name).join(', ') ?? ''
      }`;
      break;
    default:
  }

  return {
    description,
    descriptionNode,
  };
};

const toDasTrackAlertDescription = (alert: DastrackAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  switch (alert.alertType) {
    case AlertType.UWB_ENTER:
    case AlertType.UWB_EXIT:
      description =
        alert.collision && alert.collision.worker?.name
          ? `${alert.collision.worker.name} (${alert.tagId}) - ${t(
              `alert-type.${alert.alertType}`,
            )} `
          : `Tag: ${alert.tagId} - ${t(`alert-type.${alert.alertType}`)}`;
      descriptionNode =
        alert.collision && alert.collision.worker?.name
          ? `${alert.collision.worker.name} (${alert.tagId}) - ${t(
              `alert-type.${alert.alertType}`,
            )} `
          : `Tag: ${alert.tagId} - ${t(`alert-type.${alert.alertType}`)}`;
      break;
  }
  return {
    description,
    descriptionNode,
  };
};

const toDasPowerAlertDescription = (alert: DaspowerAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  description = `${t(`alert-type.${AlertType.DASPOWER_ALERT}`)} ( ${
    alert?.actual ?? 'N/A'
  }A ${alert.condition}A )`;
  descriptionNode = (
    <>
      {t(`alert-type.${AlertType.DASPOWER_ALERT}`)} ({' '}
      <span className="alert-value">{`${
        alert.actual?.toFixed(3) ?? 'N/A'
      }A`}</span>{' '}
      / {`${alert.condition}A`})
    </>
  );
  return {
    description,
    descriptionNode,
  };
};

const toAreaAlertDescription = (
  alert: AreaAlert,
  t: TFunction,
  options?: {
    areaName?: string;
    areaType?: string;
    pipes?: Array<Pipe>;
  },
) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  const areaType = options?.areaType
    ? t(`dashboard:area-management.type.tab-bar.${options?.areaType}`)
    : '';
  description = `${t(`alert-type.${AlertType.AREA_ALERT}`, {
    areaType: `${t(
      `dashboard:area-management.type.tab-bar.${options?.areaType}`,
    )} ( ${options?.areaName} )`,
  })}`;

  descriptionNode = (
    <Trans i18nKey="area-alert-description">
      <span className="alert-value">
        {{
          // @ts-ignore
          areaType,
        }}
      </span>
      {` ( ${options?.areaName} )`}
    </Trans>
  );
  return {
    description,
    descriptionNode,
  };
};

const toDasTempAlertDescription = (alert: DastempAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  description = `${t(`alert-type.${AlertType.DASTEMP_ALERT}`)} ( ${
    alert?.actual ?? 'N/A'
  }°C / ${alert.condition}°C )`;
  descriptionNode = (
    <>
      {t('temperature-abnormal')}
      {' ('}
      <span className="alert-value">{` ${alert?.actual ?? 'N/A'}°C`}</span>
      {` / ${alert.condition}°C )`}
    </>
  );
  return {
    description,
    descriptionNode,
  };
};

const toDasAirAlertDescription = (alert: DasairAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  let alertType: AlertType = AlertType.DASAIR_O2_ALERT;
  let unit = '';

  switch (alert.field) {
    case 'ch2o':
      alertType = AlertType.DASAIR_CH2O_ALERT;
      unit = 'ppm';
      break;
    case 'co':
      alertType = AlertType.DASAIR_CO_ALERT;
      unit = 'ppm';
      break;
    case 'co2':
      alertType = AlertType.DASAIR_CO2_ALERT;
      unit = 'ppm';
      break;
    case 'nh3':
      alertType = AlertType.DASAIR_NH3_ALERT;
      unit = 'ppm';
      break;
    case 'o2':
      alertType = AlertType.DASAIR_O2_ALERT;
      unit = '%';
      break;
    case 'pm10':
      alertType = AlertType.DASAIR_PM10_ALERT;
      unit = 'μg/㎥';
      break;
    case 'pm2_5':
      alertType = AlertType.DASAIR_PM2_5_ALERT;
      unit = 'μg/㎥';
      break;
    case 'voc':
      alertType = AlertType.DASAIR_VOC_ALERT;
      unit = t('voc-unit');
      break;
    default:
  }
  description = `${t(`alert-type.${alertType}`)} ( ${alert.actual}${unit} / ${
    alert.condition
  }${unit} )`;
  descriptionNode = (
    <>
      {t(`alert-type.${alertType}`)}
      {' ('}
      <span className="alert-value">{` ${alert.actual.toFixed(
        2,
      )}${unit}`}</span>
      {` / ${alert.condition}${unit} )`}
    </>
  );
  return {
    description,
    descriptionNode,
  };
};

const toDasGasAlertDescription = (alert: DasGasAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  let unit = '';

  switch (alert.alert.type) {
    case AlertType.DASGAS_O2_ALERT:
    case AlertType.DASGAS_O2_TOO_LOW_ALERT:
    case AlertType.DASGAS_SO2_ALERT:
      unit = '%';
      break;
    case AlertType.DASGAS_CO_ALERT:
    case AlertType.DASGAS_CO2_ALERT:
    case AlertType.DASGAS_CH4_ALERT:
    case AlertType.DASGAS_H2S_ALERT:
      unit = 'ppm';
      break;
    case AlertType.DASGAS_HC_ALERT:
      unit = '%LEL';
      break;
    default:
  }

  const actual = alert.alert.value?.threshold;
  description = `${t(`alert-type.${alert.alert.type}`)} ( ${
    actual ?? 'N/A'
  }${unit})`;
  descriptionNode = (
    <>
      {t(`alert-type.${alert.alert.type}`)}
      {actual && (
        <>
          {' ('}
          <span className="alert-value">{` ${
            actual ? actual.toFixed(2) : ''
          }${unit}`}</span>
          {`)`}
        </>
      )}
    </>
  );
  return {
    description,
    descriptionNode,
  };
};

const toDasWaterAlertDescription = (
  alert: DaswaterLAlert,
  t: TFunction,
  options?: {
    areaName?: string;
    areaType?: string;
    pipes?: Array<Pipe>;
  },
) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  if (alert.field === 'isTilt' && alert.actual === -1) {
    description = `${t(`alert-type.daswater-L_tilt`)}`;
    descriptionNode = description;
  } else if (alert.field === 'distance') {
    const pipe = findTheClosestPipe(options?.pipes, alert);
    const height = pipe?.height ?? 0;
    const actual = height - alert.actual / 10;
    const minDistance = pipe?.distanceMin ?? 0;
    const maxDistance = pipe?.distanceMax ?? 0;
    let status = '';
    if (alert.trigger === 'above') {
      status = ` < ${minDistance.toFixed(2)}cm )`;
    } else {
      status = ` > ${maxDistance.toFixed(2)}cm )`;
    }

    description = `${t(
      `alert-type.daswater-L_${alert.trigger}`,
    )} ( ${actual.toFixed(1)}cm ${status}`;
    descriptionNode = (
      <>
        {`${t(`alert-type.daswater-L_${alert.trigger}`)}`}
        {' ('}
        <span className="alert-value">{` ${actual.toFixed(1)}cm`}</span>
        {status}
      </>
    );
  } else if (alert.field === 'battery') {
    description = `${t(`alert-type.daswater-L_battery`)}`;
    descriptionNode = (
      <>
        {t(`alert-type.daswater-L_battery`)}
        {' ('}
        <span className="alert-value">{` ${alert.actual}%`}</span>
        {` < ${alert.condition}% )`}
      </>
    );
  }
  return {
    description,
    descriptionNode,
  };
};

const toDasLockAlertDescription = (alert: DaslockAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  description = t(`alert-type.${alert.alert.type}`);
  descriptionNode = t(`alert-type.${alert.alert.type}`);
  return {
    description,
    descriptionNode,
  };
};

const toDasCASAlertDescription = (alert: DascasAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  switch (alert.alertType) {
    case AlertType.UWB_ENTER:
    case AlertType.UWB_EXIT:
      description =
        alert.collision && alert.collision.worker?.name
          ? `${alert.collision.worker.name} (${alert.tagId}) - ${t(
              `alert-type.${alert.alertType}`,
            )} `
          : `Tag: ${alert.tagId} - ${t(`alert-type.${alert.alertType}`)}`;
      descriptionNode =
        alert.collision && alert.collision.worker?.name
          ? `${alert.collision.worker.name} (${alert.tagId}) - ${t(
              `alert-type.${alert.alertType}`,
            )} `
          : `Tag: ${alert.tagId} - ${t(`alert-type.${alert.alertType}`)}`;
      break;
    default:
      description = '';
      descriptionNode = '';
  }
  return {
    description,
    descriptionNode,
  };
};

const toDasConcreteAlertDescription = (
  alert: DasConcreteAlert,
  t: TFunction,
) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  switch (alert.field) {
    case 'tiltAngle':
      description = `${t(`alert-dashboard:alert-type.${alert.field}`)} ( ${
        alert?.actual ?? 'N/A'
      } / ${alert.condition} )`;
      descriptionNode = (
        <>
          {t(`alert-dashboard:alert-type.${alert.field}`)} ({' '}
          <span className="alert-value">{`${
            alert.actual?.toFixed(3) ?? 'N/A'
          }`}</span>{' '}
          / {`${alert.condition}`})
        </>
      );
      break;
    case 'status':
      description = `${t(`alert-dashboard:equipment-abnormal`)}`;
      descriptionNode = t(`alert-dashboard:equipment-abnormal`);
      break;
  }
  return {
    description,
    descriptionNode,
  };
};

const toAssetAlertDescription = (alert: AssetAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  description = t(`alert-type.${alert.field}`);
  descriptionNode = t(`alert-type.${alert.field}`);
  return {
    description,
    descriptionNode,
  };
};

const toAiAlertDescription = (alert: AiAlert, t: TFunction) => {
  let description: string = '';
  let descriptionNode: React.ReactNode = undefined;
  description = t(`alert-type.ai.${alert.field}`.replace(/_/g, '-'));
  descriptionNode = t(`alert-type.ai.${alert.field}`.replace(/_/g, '-'));
  return {
    description,
    descriptionNode,
  };
};

const toAlertDescription = (
  typeClass: DeviceType | 'area' | 'deviation' | 'dascas_g' | 'asset' | 'ai',
  alert:
    | DasloopAlert
    | DastrackAlert
    | DaspowerAlert
    | AreaAlert
    | DastempAlert
    | DasairAlert
    | DascartAlert
    | DeviationAlert
    | DaswaterLAlert
    | DaslockAlert
    | DasConcreteAlert
    | AiAlert,
  t: TFunction,
  options?: {
    areaName?: string;
    areaType?: string;
    pipes?: Array<Pipe>;
  },
) => {
  let descriptionObj: {
    description: string;
    descriptionNode: ReactNode;
  } = {
    description: '',
    descriptionNode: undefined,
  };

  if (typeClass === 'dasloop') {
    descriptionObj = toDasLoopAlertDescription(alert as DasloopAlert, t);
  } else if (typeClass === 'dastrack') {
    descriptionObj = toDasTrackAlertDescription(alert as DastrackAlert, t);
  } else if (typeClass === 'daspower') {
    descriptionObj = toDasPowerAlertDescription(alert as DaspowerAlert, t);
  } else if (typeClass === 'area') {
    descriptionObj = toAreaAlertDescription(alert as AreaAlert, t, options);
  } else if (typeClass === 'dastemp') {
    descriptionObj = toDasTempAlertDescription(alert as DastempAlert, t);
  } else if (typeClass === 'dasair') {
    descriptionObj = toDasAirAlertDescription(alert as DasairAlert, t);
  } else if (typeClass === 'dasgas') {
    descriptionObj = toDasGasAlertDescription(alert as DasGasAlert, t);
  } else if (typeClass === 'daswater') {
    descriptionObj = toDasWaterAlertDescription(alert as DaswaterLAlert, t);
  } else if (typeClass === 'daslock') {
    descriptionObj = toDasLockAlertDescription(alert as DaslockAlert, t);
  } else if (typeClass === 'dascas_g' || typeClass === 'dascas') {
    descriptionObj = toDasCASAlertDescription(alert as DascasAlert, t);
  } else if (typeClass === 'dasconcrete') {
    descriptionObj = toDasConcreteAlertDescription(
      alert as DasConcreteAlert,
      t,
    );
  } else if (typeClass === 'asset') {
    descriptionObj = toAssetAlertDescription(alert as AssetAlert, t);
  } else if (typeClass === 'ai' || typeClass === 'das_ai_box') {
    descriptionObj = toAiAlertDescription(alert as AiAlert, t);
  }

  return descriptionObj;
};

export default toAlertDescription;
