import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';

import Loading from '../../components/Loading';
import EditGroupOfFieldResource from '../../components/resource/edit-group-list/EditGroupOfFieldResource';
import EditGroupOfResource from '../../components/resource/edit-group-list/EditGroupOfResource';

import ConfirmBind from '../../pages/project/setting/ConfirmBind';
import EquipmentManagementPage from '../../pages/project-setting/equipment-management';
import EditEquipmentManagement from '../../pages/project-setting/equipment-management/EditEquipmentManagement';
import PlantManagementPage from '../../pages/project-setting/plant-management';
import EditPlantManagement from '../../pages/project-setting/plant-management/EditPlantManagement';
import EditVehicleGroupListManagement from '../../pages/project-setting/vehicle-management/EditVehicleGroupList';

const ProjectSettingPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting'
    ),
);

const ProjectMemberPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/project-member'
    ),
);

const InvitationListPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/invitation-list'
    ),
);

const DeviceManagementPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project/setting/Devices'
    ),
);

const ConnectionLostPageComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/connection-lost-management'
    ),
);

const CCTVSettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/cctv/CCTVSetting'
    ),
);

const PinLocationDragDialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project" */ '../../components/PinLocationDragDialog'
    ),
);

const CreateCCTVComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/cctv/CreateCCTV'
    ),
);

const AOASettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/locator/AOASetting'
    ),
);

const PinLocationDialog3DPlusComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../components/PinLocationDialog3DPlus'
    ),
);

const DasairSettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/dasair/DasairSetting'
    ),
);

const AOATagSettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/aoa-tag/AOATagSetting'
    ),
);

const BeaconSettingComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../pages/project/setting/locator/BeaconSetting'
    ),
);

const FloorPlantManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/floor-plan-management'
    ),
);

const EditFloorPlanialogComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "project-setting-page" */ '../../components/EditFloorPlanDialogMap'
    ),
);

const WorkerManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/worker-management'
    ),
);

const EquipmentCreateManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/equipment-management/TargetOperationDialog'
    ),
);

const ViewEquipmentComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/equipment-management/ViewEquipment'
    ),
);

const EditEquipmentComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/equipment-management/EditEquipment'
    ),
);

const ContactManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/contact-management'
    ),
);

const WebhookManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/webhook-management'
    ),
);

const TowerCraneManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/tower-crane-management'
    ),
);

const AiManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/ai-management'
    ),
);

const ConcreteManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/concrete-management'
    ),
);

const VehicleGroupManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/vehicle-management'
    ),
);

const PipeGroupManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/pipe-management'
    ),
);

const PipeCreateManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/pipe-management/Operation'
    ),
);

const PipeViewManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/pipe-management/View/index'
    ),
);

const PipeEditManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/pipe-management/Edit'
    ),
);

const EditPipeGroupListManagement = lazy(
  () =>
    import(
      /* webpackChunkName : "projectSetting" */ '../../pages/project-setting/pipe-management/EditPipeGroupList'
    ),
);

const EnvironmentGroupManagementComponent = lazy(
  () =>
    import(
      /* webpackChunkName: "projectSetting" */ '../../pages/project-setting/environment-management'
    ),
);

const projectSettingRouter = (
  <Route path="project-setting" element={<Outlet />}>
    <Route
      path=":projectId"
      element={
        <Suspense fallback={<Loading />}>
          <ProjectSettingPageComponent />
        </Suspense>
      }
    >
      <Route
        index
        element={
          <Suspense fallback={<Loading />}>
            <ProjectMemberPageComponent />
          </Suspense>
        }
      />
      <Route
        path="invitation-list"
        element={
          <Suspense fallback={<Loading />}>
            <InvitationListPageComponent />
          </Suspense>
        }
      />
      <Route
        path="devices"
        element={
          <Suspense fallback={<Loading />}>
            <DeviceManagementPageComponent />
          </Suspense>
        }
      >
        <Route
          path="assign"
          element={
            <Suspense fallback={<Loading />}>
              <ConfirmBind />
            </Suspense>
          }
        />
        <Route
          path="cctv-setting"
          element={
            <Suspense fallback={<Loading />}>
              <CCTVSettingComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>
        <Route
          path="create-cctv"
          element={
            <Suspense fallback={<Loading />}>
              <CreateCCTVComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>

        <Route
          path="aoa-setting"
          element={
            <Suspense fallback={<Loading />}>
              <AOASettingComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>

        <Route
          path="dasair-setting"
          element={
            <Suspense fallback={<Loading />}>
              <DasairSettingComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDragDialogComponent />}
          />
        </Route>

        <Route
          path="aoa-tag-setting"
          element={
            <Suspense fallback={<Loading />}>
              <AOATagSettingComponent />
            </Suspense>
          }
        />
        <Route
          path="beacon-setting"
          element={
            <Suspense fallback={<Loading />}>
              <BeaconSettingComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>
      </Route>
      <Route
        path="floor-plan-management"
        element={
          <Suspense fallback={<Loading />}>
            <FloorPlantManagementComponent />
          </Suspense>
        }
      >
        <Route
          path="set-floor-plan"
          element={<EditFloorPlanialogComponent />}
        />
      </Route>
      <Route
        path="worker-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <WorkerManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="worker-group-management/edit-group-list"
        element={
          <Suspense fallback={<Loading />}>
            <EditGroupOfResource />
          </Suspense>
        }
      />
      <Route
        path="plant-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <PlantManagementPage />
          </Suspense>
        }
      />
      <Route
        path="plant-group-management/edit-plant-group"
        element={
          <Suspense fallback={<Loading />}>
            <EditPlantManagement />
          </Suspense>
        }
      />
      <Route
        path="equipment-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <EquipmentManagementPage />
          </Suspense>
        }
      >
        <Route
          path="create"
          element={
            <Suspense fallback={<Loading />}>
              <EquipmentCreateManagementComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDragDialogComponent />}
          />
        </Route>

        <Route
          path="view"
          element={
            <Suspense fallback={<Loading />}>
              <ViewEquipmentComponent />
            </Suspense>
          }
        />
        <Route
          path="edit"
          element={
            <Suspense fallback={<Loading />}>
              <EditEquipmentComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDragDialogComponent />}
          />
        </Route>
      </Route>
      <Route
        path="equipment-group-management/edit-equipment-group"
        element={
          <Suspense fallback={<Loading />}>
            <EditEquipmentManagement />
          </Suspense>
        }
      >
        <Route
          path="view"
          element={
            <Suspense fallback={<Loading />}>
              <ViewEquipmentComponent />
            </Suspense>
          }
        />
        <Route
          path="edit"
          element={
            <Suspense fallback={<Loading />}>
              <EditEquipmentComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDragDialogComponent />}
          />
        </Route>
      </Route>
      <Route
        path="vehicle-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <VehicleGroupManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="vehicle-group-management/edit-vehicle-group"
        element={
          <Suspense fallback={<Loading />}>
            <EditVehicleGroupListManagement />
          </Suspense>
        }
      />
      <Route
        path="pipe-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <PipeGroupManagementComponent />
          </Suspense>
        }
      >
        <Route
          path="create"
          element={
            <Suspense fallback={<Loading />}>
              <PipeCreateManagementComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>
        <Route
          path="view"
          element={
            <Suspense fallback={<Loading />}>
              <PipeViewManagementComponent />
            </Suspense>
          }
        />
        <Route
          path="edit"
          element={
            <Suspense fallback={<Loading />}>
              <PipeEditManagementComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>
      </Route>
      <Route
        path="pipe-group-management/edit-pipe-group"
        element={
          <Suspense fallback={<Loading />}>
            <EditPipeGroupListManagement />
          </Suspense>
        }
      >
        <Route
          path="view"
          element={
            <Suspense fallback={<Loading />}>
              <PipeViewManagementComponent />
            </Suspense>
          }
        />
        <Route
          path="edit"
          element={
            <Suspense fallback={<Loading />}>
              <PipeEditManagementComponent />
            </Suspense>
          }
        >
          <Route
            path="pin-location"
            element={<PinLocationDialog3DPlusComponent />}
          />
        </Route>
      </Route>
      <Route
        path="environment-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <EnvironmentGroupManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="environment-group-management/edit-group-list"
        element={
          <Suspense fallback={<Loading />}>
            <EditGroupOfResource />
          </Suspense>
        }
      />
      <Route
        path="crane-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <TowerCraneManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="crane-group-management/edit-group-list"
        element={
          <Suspense fallback={<Loading />}>
            <EditGroupOfResource />
          </Suspense>
        }
      />
      <Route
        path="concrete-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <ConcreteManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="concrete-group-management/edit-structure-list"
        element={
          <Suspense fallback={<Loading />}>
            <EditGroupOfFieldResource />
          </Suspense>
        }
      />
      <Route
        path="ai-group-management"
        element={
          <Suspense fallback={<Loading />}>
            <AiManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="contact"
        element={
          <Suspense fallback={<Loading />}>
            <ContactManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="webhook-management"
        element={
          <Suspense fallback={<Loading />}>
            <WebhookManagementComponent />
          </Suspense>
        }
      />
      <Route
        path="lost-connection-management"
        element={
          <Suspense fallback={<Loading />}>
            <ConnectionLostPageComponent />
          </Suspense>
        }
      >
        <Route
          path="assign"
          element={
            <Suspense fallback={<Loading />}>
              <ConfirmBind />
            </Suspense>
          }
        />
      </Route>
    </Route>
  </Route>
);

export default projectSettingRouter;
